// extracted by mini-css-extract-plugin
export var acceptedPayment = "features-module--acceptedPayment--48442";
export var acceptedPaymentModalLogo = "features-module--acceptedPaymentModalLogo--b9ba2";
export var additionalBadge = "features-module--additionalBadge--c94a7";
export var animatedgradient = "features-module--animatedgradient--78eb2";
export var center = "features-module--center--ce647";
export var checkout = "features-module--checkout--ab07b";
export var checkoutBtn = "features-module--checkoutBtn--f82a6";
export var colActive = "features-module--colActive--ffe1d";
export var content = "features-module--content--9ba81";
export var continuePaymentMethodButton = "features-module--continuePaymentMethodButton--f71e4";
export var discount = "features-module--discount--7b183";
export var free = "features-module--free--3b440";
export var image = "features-module--image--21fc8";
export var inputEmail = "features-module--inputEmail--6a88b";
export var list = "features-module--list--aa290";
export var loginBtnContainer = "features-module--loginBtnContainer--1736a";
export var monthly = "features-module--monthly--39c68";
export var name = "features-module--name--d0634";
export var packageFeatures = "features-module--packageFeatures--31972";
export var packagePrice = "features-module--packagePrice--31a03";
export var packageTitle = "features-module--packageTitle--e7fcf";
export var packages = "features-module--packages--4adeb";
export var paymentMethodItem = "features-module--paymentMethodItem--a8790";
export var paymentMethodModal = "features-module--paymentMethodModal--61c00";
export var price = "features-module--price--e1d38";
export var realPrice = "features-module--realPrice--ad38d";
export var ribbon = "features-module--ribbon--676f6";
export var sample = "features-module--sample--2ef60";
export var section = "features-module--section--8e0ef";
export var selected = "features-module--selected--ef53a";
export var semester = "features-module--semester--ee162";
export var smallText = "features-module--smallText--f04ee";
export var themes = "features-module--themes--6fb5c";
export var title = "features-module--title--39cd0";
export var titleSub = "features-module--titleSub--1f866";
export var tooltip = "features-module--tooltip--4a45b";
export var underline = "features-module--underline--4c912";
export var verified = "features-module--verified--4f1ec";