import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"

import * as css from "./FrequentQuestions.module.css"

import { Link } from "@gatsbyjs/reach-router"
import * as Scroll from 'react-scroll';
import { faTelegram, faTwitter } from "@fortawesome/free-brands-svg-icons"
var scroll = Scroll.animateScroll;

// markup
const FrequentQuestions = () => {
    return (
        <>
            <div className={css.section}>
                <Container className={css.container}>
                    <Row>
                        <Col sm={12} className={css.colLeftSide}>
                            <div className={css.featureHeading}>Sering Ditanyakan</div>
                            <div className={css.featureSummary}>
                                Beberapa pertanyaan berikut sering ditanyakan oleh orang-orang.
                            </div>

                        </Col>
                    </Row>
                    <Row className={css.content}>
                        <Col md={6} sm={12}>
                            <div className={css.titleBold}>Apa itu KTP Anabul?</div>
                            <p>
                                Aplikasi yang memungkinkanmu membuat KTP untuk Anabul (Anak Bulu) dengan mudah. Sekaligus membantu menemukan anabul yang hilang dengan fitur <a href="https://twitter.com/huedaya/status/1606303783482527744" target="_blank">Mode Anabul Hilang</a>.
                            </p>
                        </Col>
                        <Col md={6} sm={12}>

                            <div className={css.titleBold}>Bagaimana Cara Mendaftarkan Akun Baru?</div>
                            <p>
                                Sangat sederhana, masukan alamat E-mail, kemudian kunjungi tautan yang dikirimkan melalui E-mail.
                            </p>
                        </Col>
                    </Row>
                    <Row className={css.content}>

                        <Col md={6} sm={12}>
                            <div className={css.title}>Berapa banyak KTP yang bisa didaftarkan?</div>
                            <p>
                                Untuk akun Basic (gratis) hanya bisa mendaftarkan 1 KTP, namun bisa di-<i>upgrade</i> ke akun Pro yang mampu menyimpan hingga 10 KTP.

                            </p>
                        </Col>
                        <Col md={6} sm={12}>

                            <div className={css.title}>Apa yang terjadi jika akun Pro habis?</div>
                            <p>
                                Ketika akun Pro sudah melewati masa aktif, maka layanan Profil Verified tetap ada namun fitur seperti pilihan tema tidak bisa diakses.

                                KTP yang telah terdaftar juga masih tetap bisa diakses dan diubah.
                            </p>
                        </Col>
                    </Row>
                    <Row className={css.content}>
                        <Col md={6} sm={12}>

                            <div className={css.title}>Apakah saya bisa membeli 1 tahun sekaligus?</div>
                            <p>
                                Bisa, cukup membeli 2 kali paket 6 bulan, maka masa aktif akun Pro akan terakumulasi.

                            </p>
                        </Col>

                        <Col md={6} sm={12}>
                            <div className={css.title}>Mengapa akun Pro dan Unduh KTP tidak gratis?</div>
                            <p>
                                Agar situs ini bisa diakses seperti sekarang, dibutuhkan penyewaan server dan domain yang tidak murah.{' '}
                                Sehingga penetapan tarif sangat masuk akal agar layanan bisa terus berjalan.
                            </p>
                        </Col>
                    </Row>
                    <Row className={css.content}>
                        <Col md={6} sm={12}>
                            <div className={css.title}>Apakah pembayaran aman?</div>
                            <p>
                                Ya, semua pembayaran akan dikelola oleh Midtrans yang telah mendapat izin beroperasi dari Bank Indonesia dan Kominfo.
                                <br />
                                Akun kami juga sudah melalui tahap verifikasi sesuai dengan peraturan yang ada.
                            </p>
                        </Col>
                        <Col md={6} sm={12}>
                            <div className={css.title}>Adakah bantuan jika aplikasi bermasalah?</div>
                            <p>
                                Jika terdapat kendala saat menggunakan aplikasi ini, harap mengubungi pembuat aplikasi ini yaitu {' '}
                                <FontAwesomeIcon icon={faTelegram} />  <a href="https://t.me/huedaya" target="_blank">@huedaya </a>.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default FrequentQuestions
